<template>
  <div>
    <v-card class="mx-auto">
       <v-card class="mx-auto"  color="indigo darken-4">
      <v-card-title style="color: white">SCV Pesca</v-card-title>
    </v-card>
    </v-card>
  </div>
</template>


<script>
export default {
};
</script>

<style lang="scss" scoped></style>
